html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body, input, button {
  text-align: center;
  font: 15px / 1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

textarea {
  box-sizing: border-box;
  resize: none;
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 95%;
  height: 95%;
  padding: 7px 20px;
  font-size: 16px;
}
/*# sourceMappingURL=index.31cfa52e.css.map */
