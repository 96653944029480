html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body,
input,
button {
  font: 15px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
  text-align: center;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

textarea {
  box-sizing: border-box;

  height: 95%;
  width: 95%;

  padding: 7px 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
